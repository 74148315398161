<template>

	<div :style="{direction: yuyan.now=='hanyu'?'ltr':'rtl',textAlign: yuyan.now=='hanyu'? 'left':'right'}">
		<el-card v-for="item in paperList" :key="item.id" style="margin-bottom: 10px; line-height: 30px;">

			<el-row :gutter="20">
				<el-col :span="12">
					{{yuyan.time}}：{{ item.createTime }}
				</el-col>

				<el-col :span="12">
					{{yuyan.use_time}}：{{ item.userTime }} {{yuyan.fenzhong}}
				</el-col>

				<el-col :span="12">
					{{yuyan.jiaojuan_score}}：{{ item.userScore }}
				</el-col>

				<el-col :span="12">
					{{yuyan.grade_ispass}}：{{item.userScore > item.qualifyScore ?yuyan.is:yuyan.no}}
				</el-col>

				<el-col :span="12">
					{{yuyan.status}}：{{ item.state}}
				</el-col>

			</el-row>

		</el-card>

	</div>

</template>

<script>
	// import { listPaper } from '@/api/paper/paper'

	export default {
		name: 'UserPaperList',
		props: {
			paperList: {
				type: Array
			}
		},

		data() {
			return {
				yuyan: {},
				value1: null
			}
		},

		watch: {
			// 语言
			'$store.state.language_num'(to, from) {
				this.yuyan = this.$language()
			}
		},

		created() {
			this.yuyan = this.$language()
		},
		methods: {}
	}
</script>

<style>

</style>
