
import {request} from '@/sujs/http.js'
export function getExamData(data){
	return request({
		url:'/exam/exam/online-paging',
		method:"POST",
		data:data
	})
}
export function getExamAllDatas(data){
	return request({
		url:'/exam/exam/online-paging',
		method:"POST",
		data:data
	})
}

export function fetchDetail(id) {
	return request({
		url:'/exam/exam/detail',
		method:"POST",
		data: { id: id }
	})
}

export function createPaper(data) {
	return request({
		url:'/paper/paper/create-paper',
		method:"POST",
		data: data
	})
}

export function paperResult(data) {
	return request({
		url:'/paper/paper/paper-result',
		method:"POST",
		data: data
	})
}
