<template>
	<div class="exams" :style="{direction: yuyan.now=='hanyu'?'ltr':'rtl'}">
		<div class="exam-card">
			<div class="card" @click="startExam(item.id)" v-for="item in exam_data.records" :key="item.id">
				<div class="a" href="https://www.kaoshixing.com/case/c167">
					<div class="img">
						<img class=""
							:src="item.logo==''?'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3492645057,1429921208&fm=26&gp=0.jpg':'https://admin.rolumapp.com/upload/'+item.logo"
							:alt="yuyan.exam_pic_jiazai">
					</div>
					<div class="title" >{{item.title}}</div>
					<div class="content-ellipsis">
						{{item.content}}
					</div>
				<!-- 	<div>
						<el-button type="success" plain  @click="lookGrade(item)">👨 </el-button>
						<el-button type="success" plain>👨 </el-button>
						<el-button plain @click="startExam(item.id)">{{yuyan.exam_start}}</el-button>
						<el-button type="primary" plain>🙍</el-button>
					</div> -->
					<div class="info clearfix">
						<!-- <div class="label">
							医疗健康
						</div> -->
						<!-- <div class="time">{{yuyan.exam_end_time}}: {{item.endTime?item.endTime:yuyan.now=='hanyu'?yuyan.exam_no:'overTime'}}</div> -->
						<div class="time">{{yuyan.exam_end_time}}: {{item.endTime?item.endTime:yuyan.exam_no}}</div>
					</div>
				</div>
			</div>
			
		</div>
		<el-dialog :title="yuyan.lianxi_detail" v-model="dialogVisible" width="60%">
		
		  <div class="el-dialog-div">
		    <grade :paperList="paperList" />
		  </div>
		
		</el-dialog>
	</div>
</template>

<script>
	import grade from "../grade/paper.vue"
	import {getExamData} from '@/api/exam.js'
	export default{
		data(){
			return{
				yuyan: {},
				dialogVisible: false,
				// 考试数据
				exam_data:{},
				// 弹框数据
				paperList: [],
				// 应该是页数
				page:{
					current: 1,
					size: 10
				}
			}
		},
		components:{grade},
		watch:{
			'$store.state.language_num'(to,from){
					this.yuyan = this.$language()
			}
		},
		methods:{
			// 获取考试详情
			getXiangQData(datas) {
				let data = {
					// 错点current不知道是啥
					current: this.score_data.current,
					params: {userId: datas.userId, examId: datas.examId},
					size: 5
				}
				getXiangQData(data).then(res => {
					this.paperList = res.records
				})
			},
			startExam(id){
				this.$router.replace({
					path:'/preview',
					query:{
						examId: id
					}
				})
			},
			lookGrade(item){
				this.dialogVisible = true
				// 没此接口
				// this.getXiangQData(item)
			},
			// 
			getExamDatas(){
				// this.exam_data = getExamData(data)
				getExamData(this.page).then(res=>{
					this.exam_data = res
				})
			}
		},
		created() {
			this.yuyan = this.$language()
			this.getExamDatas()
		},
	}
</script>

<style lang="scss">
	.banquan{
		line-height: 30px;
		font-size: 12px;
	}
	
	.el-dialog-div{
	  height: 60vh;
	  overflow: auto;
	  padding: 10px;
	}
	.exams{
		text-align: center;
		padding-top: 15px;
		min-height: 100%;
		background-color: #dddddd;
	}
	.exam-card{
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	.card {
	    // width: 380px;
	    // height: 398px;
			width: 300px;
			height: 300px;
	    box-shadow: 0 0 10px 0 rgba(95,103,227,0.10);
	    border-radius: 4px;
	    margin-right: 30px;
	    margin-bottom: 30px;
	    position: relative;
	    background: #FFFFFF;
	    // float: left;
	}
	.card .a {
	    display: block;
	    padding: 25px;
	}
	.card .img {
			img{
				width: 100%;
				height: 150px;
			}
	}
	.card .title {
		margin-top: 10px;
	    font-size: 16px;
	    color: #3B426B;
	    margin-bottom: 10px;
	    font-weight: 900;
	}
	.card .content-ellipsis {
	    font-size: 12px;
	    color: #6D717C;
	    width: 100%;
			overflow-x: hidden;
			height: 50px;
	    margin-bottom: 25px;
	}
	.card .info {
	    position: absolute;
	    bottom: 10px;
	    left: 25px;
	    right: 25px;
	    font-size: 12px;
	}
</style>
